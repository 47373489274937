import React, { useEffect, useState } from "react";
import {
  View,
  Animated,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Modal,
  TouchableWithoutFeedback,
  ScrollView,
  Linking,
} from "react-native";
import { useTranslation } from "react-i18next";
import { Icon, Header, ChatIcon } from "../../components";
import { Text, TextInput, Image } from "../../ui";
import Menu from "../../ui/Menu";
import { Button } from "../../ui";
import { BaseColor, BaseStyle, useTheme } from "../../config";
import { Images } from "../../config/images";
import * as Utils from "../../utils";
import ServiceType from "../../constant/serviceType";
import { useAuth } from "../../context/Auth";
import FilterOption, {
  TPetCategory,
  TPetSize,
} from "../../components/FilterOption";
import { getAreaCode, getFirstAddress } from "../../models/location";
import { getPermissionAsync } from "../../utils/permission";
import * as Permissions from "expo-permissions";
import * as Location from "expo-location";
import { getSitterRecommend } from "../../models/globals";
import { usePromise } from "../../hooks";
import LoadingSpinner from "../../components/LoadingSpinner";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Banners from "./Banners";
import Logo from "../../assets/images/fluv_tw_w.webp";
import LineIcon from "../../assets/images/line_icon.webp";
import { getAuth } from "firebase/auth";
import NearBySitter from "./NearBySitter";
import { getCurrentUserId, TServicePlace } from "../../models";
import PlaceModal from "../../components/PlaceModal";
import { minWidth } from "../../config/theme";
import { useNavigation } from "../../hooks/useNavigation";
import { MainStackScreenNavigationProp } from "../../config/typography";
import { Form } from "../../models/Form";

const HomeScreen = () => {
  const navigation = useNavigation<MainStackScreenNavigationProp>();

  const [heightHeader, setHeightHeader] = useState(Utils.heightHeader());
  const { t } = useTranslation();
  const deltaY = new Animated.Value(0);
  const heightImageBanner = 150;
  const marginTopBanner = 10;
  const { colors } = useTheme();
  const [serviceType] = useState(ServiceType);
  const [isShowPlaceModal, setIsShowPlaceModal] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<TServicePlace | undefined>(
    undefined,
  );
  const [category, setCategory] = useState<TPetCategory[] | undefined>(
    undefined,
  );
  const [size, setSize] = useState<TPetSize[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const topSitterIds = usePromise(async () => await getSitterRecommend(), []);
  const auth = getAuth();
  const { currentUser } = auth;
  useEffect(() => {
    setIsLoading(true);
    if (topSitterIds === null) return;
    setIsLoading(false);
  }, [topSitterIds]);
  const getCurrentLocation = async () => {
    if (await getPermissionAsync(Permissions.LOCATION_FOREGROUND)) {
      const locationData = await Location.getCurrentPositionAsync({
        accuracy: Location.Accuracy.Lowest,
      });
      const {
        coords: { latitude, longitude },
      } = locationData || { coords: {} };

      const address = (await getFirstAddress(latitude, longitude)) as any;
      const areaCode = (await getAreaCode({ latitude, longitude })) || "";
      setSelectedPlace({
        name: address["formatted_address"],
        address: address["formatted_address"],
        areaCode: areaCode,
        location: {
          latitude: latitude,
          longitude: longitude,
        },
      });
      AsyncStorage.setItem(
        "currentLocation",
        `${
          address["formatted_address"]
        }&${areaCode.toString()}&${latitude.toString()}&${longitude.toString()}`,
      );
    }
  };

  const getCurrentAsyncStorageLocation = () => {
    AsyncStorage.getItem("currentLocation").then(
      (currentLocation: string | null) => {
        if (currentLocation !== null) {
          const location = currentLocation.split("&") as Array<string>;
          setSelectedPlace({
            name: location[0],
            address: location[0],
            areaCode: location[1],
            location: {
              latitude: parseInt(location[2]),
              longitude: parseInt(location[3]),
            },
          });
        } else {
          getCurrentLocation();
        }
      },
    );
  };

  useEffect(() => {
    getSitterRecommend();
    getCurrentAsyncStorageLocation();
  }, []);

  const getFilterOption = (category: TPetCategory[], size: TPetSize[]) => {
    setCategory(category);
    setSize(size);
  };

  const renderIconService = () => {
    return (
      <FlatList
        data={serviceType}
        numColumns={4}
        keyExtractor={(item) => item.name}
        renderItem={({ item }) => {
          return (
            <TouchableOpacity
              style={styles.itemService}
              activeOpacity={0.9}
              onPress={() => {
                if (item.route === "Link") {
                  window.open(
                    Form.getRecommendSitterLink({
                      consumerId: getCurrentUserId(),
                    }),
                    "_blank",
                  );
                  return;
                }
                if (selectedPlace) {
                  navigation.navigate("SearchScreen", {
                    type: item.type,
                    category,
                    size,
                    areaCode: selectedPlace?.areaCode,
                  });
                  AsyncStorage.setItem(
                    "searchQuery",
                    `${item.type}&${category}&${size}&${selectedPlace?.areaCode}`,
                  );
                } else {
                  alert(t(`search:place_tips_to_consumer`));
                }
              }}
            >
              <View
                style={[styles.iconContent, { backgroundColor: colors.card }]}
              >
                <Icon
                  name={t(item.icon)}
                  color={
                    item.type === "order_now" ? colors.accent : colors.primary
                  }
                  size={18}
                  solid
                />
              </View>
              <Text footnote grayColor numberOfLines={1}>
                {t(`search:${item.name}`)}
              </Text>
            </TouchableOpacity>
          );
        }}
      />
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={BaseStyle.safeAreaView}>
        <LoadingSpinner visible={isLoading} />
        <Menu
          visible={isShowMenu}
          onDismiss={() => {
            setIsShowMenu(false);
          }}
        />
        <Header
          style={BaseStyle.menu}
          styleLeft={{}}
          onPressLeft={() => {
            navigation.navigate("UserTab", { screen: "Home" });
          }}
          renderLeft={() => {
            return (
              <img
                src={String(Logo)}
                style={BaseStyle.headerLogoStyle}
                loading="lazy"
                alt="Fluv logo"
              />
            );
          }}
          title=""
          subTitle=""
          styleRight={{}}
          renderRight={() => {
            return (
              <View style={{ flexDirection: "row" }}>
                {getCurrentUserId() !== null ? (
                  <TouchableOpacity
                    style={{
                      width: 32,
                      height: 32,
                      marginRight: 15,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 16,
                    }}
                    onPress={() => {
                      navigation.navigate("UserTab", { screen: "Chat" });
                    }}
                  >
                    <ChatIcon isSidebar={false} />
                  </TouchableOpacity>
                ) : (
                  <></>
                )}
                <TouchableOpacity
                  onPress={() => {
                    setIsShowMenu(true);
                  }}
                  style={{
                    width: 32,
                    height: 32,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 16,
                  }}
                >
                  <View>
                    <Icon name="bars" size={18} color={BaseColor.whiteColor} />
                  </View>
                </TouchableOpacity>
              </View>
            );
          }}
          onPressRight={() => {}}
        />
        {/* <Animated.Image
          source={Images.banners.banner1}
          style={[
            styles.imageBackground,
            {
              height: deltaY.interpolate({
                inputRange: [
                  0,
                  100,
                  100,
                ],
                outputRange: [heightImageBanner, heightHeader, 0],
              }),
            },
          ]}
        /> */}
        <ScrollView>
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                paddingHorizontal: 20,
                width: "50%",
                minWidth: minWidth,
              }}
            >
              <View
                style={[
                  styles.searchForm,
                  {
                    marginTop: marginTopBanner,
                    backgroundColor: colors.background,
                    borderColor: colors.border,
                    shadowColor: colors.border,
                  },
                ]}
              >
                {/* Filter */}
                <TouchableOpacity
                  onPress={() => {
                    setIsShowPlaceModal(true);
                  }}
                  activeOpacity={0.5}
                  style={[
                    BaseStyle.textInput,
                    { backgroundColor: colors.card },
                  ]}
                >
                  <Icon
                    style={{ marginRight: 5 }}
                    name="map-marker-alt"
                    color={colors.accent}
                    size={16}
                  />
                  <Text body1 style={{ fontSize: 14 }}>
                    {selectedPlace !== undefined &&
                    Object.keys(selectedPlace).length !== 0
                      ? selectedPlace.address
                      : t("search:address_setting")}
                  </Text>
                </TouchableOpacity>
                <PlaceModal
                  visible={isShowPlaceModal}
                  onDismiss={(visible: any) => setIsShowPlaceModal(visible)}
                  onSelect={(place: TServicePlace) => {
                    if (Object.keys(place).length <= 0) {
                      getCurrentAsyncStorageLocation();
                    } else {
                      const latitude = place.location?.latitude.toString();
                      const longitude = place.location?.longitude.toString();
                      setSelectedPlace(place);
                      AsyncStorage.setItem(
                        "currentLocation",
                        `${
                          place.address +
                          "&" +
                          place.areaCode +
                          "&" +
                          latitude +
                          "&" +
                          longitude
                        }`,
                      );
                      window.location.reload();
                    }
                  }}
                />
                <FilterOption getFilterOption={getFilterOption} />
                {/* Filter */}
                {renderIconService()}
              </View>
            </View>
          </View>
          <View style={{ paddingVertical: 10 }}>
            <NearBySitter />
          </View>
          <Banners />
          {/* TODO (lucyliou): Padding 2em because otherwise it won't be clickable, although this is a bug, but we'll investigate later */}
          <View
            style={{
              padding: "2em",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Button
              style={{ marginVertical: 10, width: "8rem" }}
              styleText={{ fontSize: 17, fontWeight: "700" }}
              onPress={() => {
                Linking.openURL("https://bit.ly/2zP8vtG");
              }}
            >
              APP 預訂
            </Button>
            <Button
              style={{ marginVertical: 10, width: "8rem" }}
              styleText={{ fontSize: 17, fontWeight: "700" }}
              onPress={() => {
                Linking.openURL("https://lin.ee/XQ3cli4");
              }}
            >
              <img
                style={{ width: 20, height: 20 }}
                src={String(LineIcon)}
                loading="lazy"
                alt="Line icon"
              />{" "}
              客服協助
            </Button>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  imageBackground: {
    height: 150,
    width: "100%",
    position: "absolute",
  },
  searchForm: {
    padding: 10,
    borderRadius: 10,
    borderWidth: 0.5,
    width: "100%",
    shadowColor: "black",
    shadowOffset: { width: 1.5, height: 1.5 },
    shadowOpacity: 0.3,
    shadowRadius: 1,
    elevation: 1,
  },
  contentServiceIcon: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  contentCartPromotion: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  btnPromotion: {
    height: 25,
    borderRadius: 3,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  contentHiking: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 10,
  },
  promotionBanner: {
    height: 100,
    width: "100%",
    marginTop: 10,
  },
  line: {
    height: 1,
    marginTop: 10,
    marginBottom: 15,
  },
  iconContent: {
    justifyContent: "center",
    alignItems: "center",
    width: 36,
    height: 36,
    borderRadius: 18,
  },
  itemService: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    paddingTop: 10,
  },
  promotionItem: {
    width: 200,
    height: 250,
  },
  tourItem: {
    width: 135,
    height: 160,
  },
  titleView: {
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 10,
  },
  redDot: {
    backgroundColor: BaseColor.color2,
    width: 8,
    height: 8,
    borderRadius: 4,
    position: "absolute",
    right: -2,
  },
});
