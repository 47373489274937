import {
  View,
  Alert,
  TouchableOpacity,
  Platform,
  Image,
  StyleSheet,
  Linking,
} from "react-native";
import React, { useCallback, useState } from "react";
import * as Facebook from "expo-facebook";
import { FACEBOOK_CONFIG, WEB_URL } from "../../../src/constant/backend";
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  OAuthProvider,
  onAuthStateChanged,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from "firebase/auth";
import { createProfile, getProfile } from "../../models/profile";
import * as AppleAuthentication from "expo-apple-authentication";
import { generateId } from "../../utils";
import * as Crypto from "expo-crypto";

import { Flex, Text, TextInput } from "../../ui";
import { Icon } from "../../components";
import { Button } from "../../ui";
import { Trans, useTranslation } from "react-i18next";
import { BaseColor } from "../../config";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useNavigation } from "../../hooks/useNavigation";

import { auth } from "../../config/firebase";
import { minWidth } from "../../config/theme";
import { MainStackScreenNavigationProp } from "../../config/typography";

const WEBVIEW_USER_AGENT = "SF84";

const SignInScreen = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [isSignup, setIsSignup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation<MainStackScreenNavigationProp>();

  const signIn = useCallback(async (credential, fetchProfile) => {
    // loading spinner show
    // register a new user on firebase
    const auth = getAuth();

    signInWithCredential(auth, credential)
      .then((result) => {
        // navigation.navigate("UserTab", {screen: "Home"})
        navigation.pop();
        // Signed in
        // ...
      })
      .catch((error) => {
        Alert.alert("Authorize failed");
        console.error(error);
      });

    onAuthStateChanged(auth, async (user) => {
      // loading spinner hide
      if (user) {
        try {
          const profile = await getProfile(user.uid);
          if (!profile || !profile.displayName) {
            // init profile
            // loading spinner show title : creating_profile
            const profileInput = await fetchProfile(credential);
            await createProfile(profileInput);
            // loading spinner hide title : sign_in:creating_profile
          } else {
            navigation.pop();
            // navigation.navigate("UserTab", {screen: "Home"})
          }
        } catch (_) {
          Alert.alert("Create Profile failed");
        }
      } else {
        // don't alert anything because user may be null
      }
    });
  }, []);

  const fetchFacebookProfile = async (credential: any) => {
    try {
      // get facebook profile data
      const accessToken = credential["accessToken"];
      const { id, name, email, picture } = await fetch(
        `https://graph.facebook.com/v16.0/me?access_token=${accessToken}&fields=id,name,email,picture.width(1280)`
      ).then((r) => r.json());

      return {
        name,
        avatarUrl: picture.data.url,
        facebookId: id,
        facebookEmail: email,
        facebookAppId: FACEBOOK_CONFIG.appId,
      };
    } catch (e) {
      throw e;
    }
  };

  const handleSignInWithFacebook = useCallback(async () => {
    try {
      await Facebook.initializeAsync({
        appId: FACEBOOK_CONFIG.appId,
        appName: "fluv",
      });
      const {
        type,
        token,
        expirationDate,
        permissions,
        declinedPermissions,
      }: any = await Facebook.logInWithReadPermissionsAsync({
        permissions: ["public_profile", "email"],
      });
      if (type === "success") {
        // Get the user's name using Facebook's Graph API
        const credential = FacebookAuthProvider.credential(token);
        await signIn(credential, fetchFacebookProfile);
      } else {
        // type === 'cancel'
      }
    } catch ({ message }) {
      alert(`Facebook Login Error: ${message}`);
    }
  }, []);

  const handleSignInWithFacebookByWeb = useCallback(async () => {
    try {
      const auth = getAuth();
      const providerFb = new FacebookAuthProvider();
      providerFb.addScope("email");
      signInWithPopup(auth, providerFb)
        .then(async (result) => {
          const credential: any =
            FacebookAuthProvider.credentialFromResult(result);
          const accessToken = credential["accessToken"];
          const user = result.user;
          await signIn(credential, fetchFacebookProfile);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const email = error.email;
          const credential = FacebookAuthProvider.credentialFromError(error);
          console.error("error", error);
        });
    } catch {}
  }, []);

  const handleSignInWithApple = useCallback(async () => {
    const nonce = generateId(10);
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    provider.setCustomParameters({
      // Localize the Apple authentication screen in French.
      locale: "zh_TW",
    });

    signInWithPopup(auth, provider)
      .then(async (result: UserCredential) => {
        const credential = OAuthProvider.credentialFromResult(result);
        const fetchProfile = async (credential: any) => {
          return {
            appleEmail: result.user.providerData[0].email,
            name: result.user.providerData[0].displayName,
          };
        };
        await signIn(credential, fetchProfile);
      })
      .catch(() => {});
    // let options: SignInWithAppleOptions = {
    //   clientId: 'com.fluv.webapp',
    //   redirectURI: 'https://app.fluv.com/signin',
    //   scopes: 'email name',
    //   state: '12345',
    //   nonce: 'nonce',
    // };
  }, []);

  const handleOpenTou = useCallback(() => {
    Linking.openURL(`${WEB_URL}/usetreaty`);
  }, []);

  const handleOpenPrivacy = useCallback(() => {
    Linking.openURL(`${WEB_URL}/privacypolicy`);
  }, []);

  const handleSignInWithEmail = (email: string, password: string) => {
    if (email === "") {
      alert(t("sign_in:typeing_email"));
      return;
    }
    if (password === "") {
      alert(t("sign_in:typeing_password"));
      return;
    }
    setIsLoading(true);
    const auth = getAuth();
    // 測試版帳號： app@fluv.com 密碼：app123456
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigation.pop();
        // navigation.navigate("UserTab", {screen: "Home"});
        setIsLoading(false);
      })
      .catch((error) => {
        alert(t("sign_in:error_sign_in"));
        setIsLoading(false);
      });
  };

  const handleSignUpWithEmail = useCallback(() => {
    if (displayName === "" && isSignup) {
      alert(t("sign_in:typeing_display_name"));
      return;
    }
    if (
      email === "" ||
      email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) === null
    ) {
      alert(t("sign_in:typeing_email"));
      return;
    }
    if (password === "") {
      alert(t("sign_in:typeing_password"));
      return;
    }
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then(async () => {
        setPassword("");
        setIsLoading(true);
        await createProfile({
          name: isSignup ? displayName : "No name",
          avatarUrl: "",
          email: email,
        });
        handleSignInWithEmail(email, password);
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/email-already-in-use":
            handleSignInWithEmail(email, password);
            break;
          case "auth/invalid-email":
            console.debug("YCM7");
          // fallthrough
          case "auth/weak-password":
            console.debug("1S11");
            alert(t("sign_in:error_sign_in"));
            break;
          default:
            console.error("error sign up", error);
        }
      });
  }, [email, password, displayName]);

  return (
    <View style={{ flex: 1 }}>
      <LoadingSpinner visible={isLoading} />
      {/* <Image style={{flex: 1, width: undefined, height: undefined}} source={{uri: 'https://firebasestorage.googleapis.com/v0/b/fluv-dev.appspot.com/o/img_landing_thumb_1280x.jpg?alt=media&token=a18c2ef5-9aaf-4728-8eec-517a72600532'}} resizeMode="cover" /> */}
      {/* <View style={[StyleSheet.absoluteFill, styles.dark]} /> */}
      <View
        style={[
          StyleSheet.absoluteFill,
          styles.center,
          { maxWidth: minWidth, margin: "auto" },
        ]}
      >
        <View style={{ flex: 1 }} />
        <View style={{ flex: 1, justifyContent: "center" }}>
          {/* <Image style={{ width: 280, height: 80 }} source={{uri: 'https://firebasestorage.googleapis.com/v0/b/fluv-dev.appspot.com/o/logo_white.png?alt=media&token=582455cd-024d-4e3b-a571-8844b416ad83'}} resizeMode="contain" /> */}
          <Text header bold style={[styles.title]}>
            {t("sign_in:title")}
          </Text>
        </View>
        <View style={{ flex: 3 }}>
          <Text callout>{t("sign_in:slogan1")}</Text>
        </View>

        <View style={{ flex: 9 }}>
          {navigator.userAgent !== WEBVIEW_USER_AGENT && (
            <Flex paddingBottom={12}>
              <Button
                full
                style={[styles.facebookButton, { borderRadius: 40 }]}
                onPress={
                  Platform.OS === "web"
                    ? handleSignInWithFacebookByWeb
                    : handleSignInWithFacebook
                }
              >
                {t("sign_in:sign_in_with_facebook")}
              </Button>
            </Flex>
          )}
          <Flex paddingBottom={12}>
            <Button
              full
              style={[styles.appleButton, { borderRadius: 40 }]}
              onPress={handleSignInWithApple}
            >
              <Icon name="apple" size={18} style={{ marginRight: 5 }} />
              {t("sign_in:sign_in_with_apple")}
            </Button>
          </Flex>
          <Flex paddingBottom={12}>
            <View
              style={{
                flexDirection: "row",
                maxWidth: minWidth,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View style={[styles.line]} />
              <Text style={styles.orText}>或</Text>
              <View style={[styles.line]} />
            </View>
          </Flex>
          <Flex paddingBottom={12}>
            <View>
              {isSignup ? (
                <>
                  <TextInput
                    onChangeText={(text) => setDisplayName(text)}
                    onFocus={() => {}}
                    placeholder={t("displayName")}
                    value={displayName}
                    style={[styles.textInput]}
                  />
                </>
              ) : (
                <></>
              )}
              <TextInput
                onChangeText={(text) => setEmail(text)}
                onFocus={() => {}}
                placeholder={t("email")}
                value={email}
                style={[styles.textInput]}
              />
              <TextInput
                onChangeText={(text) => setPassword(text)}
                onFocus={() => {}}
                placeholder={t("password")}
                value={password}
                style={[styles.textInput]}
                secureTextEntry={true}
              />
              <Button
                style={{ backgroundColor: BaseColor.color2 }}
                onPress={handleSignUpWithEmail}
              >
                {isSignup ? t("common:sign_up") : t("common:sign_in")}
              </Button>
            </View>
          </Flex>
          <Flex paddingBottom={12}>
            <View style={{ marginHorizontal: 12 }}>
              <Text style={styles.signupyetText}>
                {isSignup
                  ? t("sign_in:i_have_an_account")
                  : t("sign_in:have_an_account_yet")}
                <TouchableOpacity
                  onPress={() => {
                    setIsSignup(!isSignup);
                  }}
                >
                  <Text style={styles.signupText}>
                    {isSignup ? t("common:sign_in") : t("common:sign_up")}
                  </Text>
                </TouchableOpacity>
              </Text>
            </View>
          </Flex>
          {/* {
            Platform.OS === 'ios' ? (
              <Flex paddingBottom={12}>
                <AppleAuthentication.AppleAuthenticationButton 
                  buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                  buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                  cornerRadius={22}
                  style={{ width: '100%', height: 44 }}
                  onPress={handleSignInWithApple}
                />
              </Flex>
            ) : (<></>)
          } */}
          <Flex paddingTop={4}>
            <Text dividerColor caption1>
              <Trans i18nKey="sign_in:agree_tou_and_privacy">
                <Text
                  caption1
                  style={styles.blackUnderLine}
                  onPress={handleOpenTou}
                >
                  _
                </Text>
                <Text
                  caption1
                  style={styles.blackUnderLine}
                  onPress={handleOpenPrivacy}
                >
                  _
                </Text>
              </Trans>
            </Text>
          </Flex>
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  dark: {
    backgroundColor: "black",
    opacity: 0.4,
  },
  center: {
    justifyContent: "center",
    paddingHorizontal: 48,
  },
  title: {
    textAlign: "left",
  },
  slogan1: {
    color: "black",
    fontSize: 24,
    paddingBottom: 12,
    textAlign: "right",
  },
  slogan2: {
    color: "black",
    fontSize: 32,
    textAlign: "right",
  },
  orText: {
    color: "black",
    fontSize: 16,
    textAlign: "center",
    width: "12%",
  },
  line: {
    width: "44%",
    height: 1,
    borderTopWidth: 0.7,
    borderTopColor: BaseColor.grayColor,
  },
  textInput: {
    backgroundColor: BaseColor.whiteColor,
    marginBottom: 12,
    borderWidth: 1,
  },
  signupText: {
    color: BaseColor.color2,
    fontSize: 16,
    textAlign: "center",
  },
  signupyetText: {
    color: BaseColor.highlightedTextColor,
    fontSize: 16,
    textAlign: "center",
  },
  facebookButton: {
    backgroundColor: "#3961d3",
    height: 44,
  },
  appleButton: {
    backgroundColor: "black",
    height: 44,
  },
  blackUnderLine: {
    color: BaseColor.grayColor,
    textDecorationLine: "underline",
  },
});
export default SignInScreen;
