// copied from fluv mobile ./src/models/preference.ts
import { getCurrentUserId } from './auth';
import { getShortLink } from '../helpers/link';
import { WEB_URL } from '../constant/backend';

export const createSitterReferralLink = async ({
  userId,
  campaign,
}: {
  userId: string;
  campaign: 'share_by_provider' | 'share_by_user';
}): Promise<string> => {
  const myId = await getCurrentUserId();
  if (!myId) return '';

  const analytics = {
    source: 'share',
    medium: 'referral',
    campaign,
  };

  return getShortLink(`${WEB_URL}/app/u/${userId}/share`, true, analytics);
};
